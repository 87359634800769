<template>
    <div id="newReport" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="newGenerateReport"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="reporting_generateReportSubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24"/>
                                                            <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                                                            <path d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z" fill="#000000"/>
                                                            <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("reporting_editReport") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" novalidate="novalidate">
                                            <div class="kt-portlet__body">
                                                <div class="form-group form-group-last">
                                                    <div class="alert alert-secondary" role="alert">
                                                        <div class="alert-icon"><i class="flaticon-info kt-font-brand"></i></div>
                                                        <div class="alert-text">
                                                            {{ $t("reporting_generateReportFormHelp") }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">

                                                    <div class="col-lg-6">
                                                        <div class="form-group validated">
                                                            <label for="newTagForm_serialNumberInput">{{ $t("reporting_nameReport") }} *</label>
                                                            <div class="input-group validated">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                </div>
                                                                <input v-model="vName" @input="$v.vName.$touch()" type="text" @keyup="onChangeForm" class="form-control" id="newTagForm_serialNumberInput" :placeholder="$t('reporting_enterName')" />
                                                                <div v-if="!$v.vName.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div v-else-if="!$v.vName.minLen" class="invalid-feedback">
                                                                    {{ $t("error_minLengthMsg", [$v.vName.$params.minLen.min]) }}
                                                                </div>
                                                            </div>
                                                            <span class="form-text text-muted"> {{ $t("reporting_nameInfo") }}</span>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group validated">
                                                            <label for="newReportForm_modelInput">{{ $t("reporting_generateReportTemplateLabel") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-hdd-o"></i></span>
                                                                </div>
                                                                <select id="newReportForm_modelInput" class="form-control" v-model="vTemplate" v-bind:class="{ emptyColor: vTemplate == '' }">
                                                                    <option value="" disabled selected>{{ $t("reporting_generateReportEnterTemplate") }}</option>
                                                                    <option v-for="(template, index) in reportingTemplates" :key="index" :value="template">{{ $t(template) }}</option>
                                                                </select>
                                                                <div v-if="!$v.vTemplate.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("reporting_generateReportModelExample") }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-show="vTemplate && vTemplate !== 'INSTANT_INVENTORY'" class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit marginT0"></div>

                                                <div v-show="vTemplate && vTemplate !== 'INSTANT_INVENTORY'" class="row">
                                                    <div class="col-md-12">
                                                        <label class="colorGeonotif">{{ $t("reporting_generateReportPeriodFormTitle") }} </label>
                                                        <div class="input-group date col-md-8 validated" style="margin-left: -10px;">
                                                            <label class="col-form-label" style=" margin-right: 10px; ">{{ $t("reporting_generateReportFromStartTime") }}</label>
                                                            <div class="period input-group-prepend">
                                                                <span> </span>
                                                                <span class="input-group-text">
                                                                    <i class="la la-calendar"></i>
                                                                </span>
                                                            </div>
                                                            <input type="text" id="reporting_generateReportPeriodStartTimeDatePicker" :placeholder="$t('reporting_startdatetimeplaceholder')" class="form-control" />
                                                            <label class="col-form-label" style=" margin-left: 10px; margin-right: 10px; ">{{ $t("reporting_generateReportToEndTime") }}</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">
                                                                    <i class="la la-calendar"></i>
                                                                </span>
                                                            </div>
                                                            <input type="text" id="reporting_generateReportPeriodEndTimeDatePicker" :placeholder="$t('reporting_enddatetimeplaceholder')" class="form-control" />
                                                            <div v-if="!isTwoDateSelected" class="invalid-feedback" style=" margin-left: 4px; ">
                                                                {{ $t("error_twoDatesRequired") }}
                                                            </div>
                                                            <div v-if="!isCorrectEndDateTime" class="invalid-feedback" style=" margin-left: 4px; ">
                                                                {{ $t("error_endDateIsNotCorrectForReport") }}
                                                            </div>
                                                            <div v-if="!isCorrectPeriodDateTime" class="invalid-feedback" style=" margin-left: 4px; ">
                                                                {{ $t("error_PeriodeDateIsNotCorrectForReport") }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit marginT10"></div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("reporting_generateReportFileTypeFormTitle") }} </label>
                                                            <div class="kt-radio-list" style="margin-left: 10px; margin-top: 20px;">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                            <input id="editGenerateReportFileTypeCsvRadio" type="radio" v-model="vFileType" name="fileType" value="CSV" :checked="vFileType == 'CSV'" /> {{ $t("reporting_generateReportFileTypeCsv") }}
                                                                            <span></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="row" style=" margin-top: 20px; ">
                                                                    <div class="col-md-12">
                                                                        <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                            <input id="editGenerateReportFileTypeExcelRadio" type="radio" v-model="vFileType" name="fileType" value="XLS" :checked="vFileType == 'XLS'" /> {{ $t("reporting_generateReportFileTypeExcel") }}
                                                                            <span></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit marginT0"></div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("reporting_choseTypeReport") }} </label>
                                                            <div class="kt-radio-list" style="margin-left: 10px; margin-top: 20px;">
                                                                <div class="row">
                                                                    <div class="col-md-2">
                                                                        <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                            <input id="editGenerateReportFileTypeCsvRadio" type="radio" v-model="vReportType" name="reportType" value="ON_DEMAND" :checked="vReportType == 'ON_DEMAND'" /> {{ $t("reporting_generateReportTypeInstant") }}
                                                                            <span></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="row" style=" margin-top: 10px; align-items: center;">
                                                                    <div class="col-md-2">
                                                                        <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                            <input id="editGenerateReportFileTypeExcelRadio" type="radio" v-model="vReportType" name="reportType" value="SCHEDULED" :checked="vReportType == 'SCHEDULED'" /> {{ $t("reporting_generateReportTypeSchedule") }}
                                                                            <span></span>
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-md-2 schedule" v-show="vReportType === 'SCHEDULED'">
                                                                        <label for="newReportForm_scheduleInput" class="kt-margin-r-10">
                                                                            {{ $t("reporting_every") }}
                                                                        </label>
                                                                        <select id="newReportForm_scheduleInput" class="form-control" v-model="vRepeat" v-bind:class="{ emptyColor: vRepeat == '' }">
                                                                            <option v-for="(scheduler, index) in schedulerList" :key="index" :value="scheduler.key">{{ $t(scheduler.value) }}</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="col-md-4 schedule" v-show="vRepeat === 'WEEKLY' && vReportType === 'SCHEDULED'">
                                                                        <label for="newReportForm_weekDayInput" style="white-space: nowrap;" class="kt-margin-r-10">
                                                                            {{ $t("reporting_onWeekDay") }}
                                                                        </label>
                                                                        <select multiple="multiple" id="newReportForm_weekDayInput" class="form-control kt-select2">
                                                                        </select>
                                                                    </div>
                                                                    <div class="col-md-4 schedule" v-show="vRepeat === 'MONTHLY' && vReportType === 'SCHEDULED'">
                                                                        <label for="newReportForm_monthDayInput" style="white-space: nowrap;" class="kt-margin-r-10">
                                                                            {{ $t("reporting_onMonthDay") }}
                                                                        </label>
                                                                        <select multiple="multiple" id="newReportForm_monthDayInput" class="form-control kt-select2">
                                                                            <!-- <option></option> -->
                                                                        </select>
                                                                    </div>
                                                                    <div class="col-md-2 schedule" v-show="vReportType === 'SCHEDULED'">
                                                                        <label class="kt-margin-r-10">
                                                                            {{ $t("reporting_at") }}
                                                                        </label>
                                                                        <div class="input-group timepicker">
                                                                            <input class="form-control" id="reporting_timepicker" placeholder="" type="text"/>
                                                                            <div class="input-group-append">
                                                                                <span class="input-group-text"><i class="la la-clock-o"></i></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit marginT0"></div>

                                                <div class="row">
                                                    <div class="col-md-8">
                                                        <div class="form-group validated">
                                                            <label for="newReportForm_tagsInput">{{ $t("reporting_generateReportEmailAddressesKeywordsLabel") }} *</label>
                                                            <div class="input-group">
                                                                <vue-tags-input id="newReportForm_tagsInput" :separators="[' ', ',', ';']" :add-on-key="[13, ' ', ',', ';']" :placeholder="$t('reporting_generateReportEmailAddressesKeywordsPlaceholder')" v-model="vKeyword" :tags="emails" @tags-changed="tagChanged" />
                                                                <div v-if="!$v.vKeyword.email" class="invalid-feedback">
                                                                    {{ $t("error_keywordInvalidEmail") }}
                                                                </div>
                                                                <div v-if="!$v.emails.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("reporting_generateReportEmailAddressesKeywordsExample") }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    
                                                    <button id="newReportButton" @click="onUpdateReportButton" type="button" class="btn btn-brand kt-margin-r-5" :disabled="!isValidate || !isFormDataChanged">{{ $t("common_update") }}</button>
                                                    <button id="newReportCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </form>

                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>

                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
import VueTagsInput from "@johmun/vue-tags-input";
import moment from 'moment-timezone';
import i18n from "../../i18n";

const _TemplateReportName = require("../../constants/templateReport");
const listReportWeekdays = require("../../constants/reportWeekdays");
const listReportMonthDays = require("../../constants/reportMonthDays");
const listReportSchedule = require("../../constants/reportScheduleList");

// constante using in validations which check value equal to true
const mustBeTrue = (value) => value === true;

export default {
    data() {
        return {
            vTemplate: "",
            oldTemplate: "",
            vKeyword: "",
            vName: "",
            oldName: "",
            emails: [],
            oldEmails: [],
            vFileType: "CSV",
            oldFileType: "CSV",
            vReportType: "ON_DEMAND",
            oldReportType: "ON_DEMAND",
            vRepeat: "",
            oldRepeat: "",
            vStartTime: commonVueHelper.beginDay(commonVueHelper.getToDayLessXday(1)),
            oldStartTime: commonVueHelper.beginDay(commonVueHelper.getToDayLessXday(1)),
            vEndTime:  commonVueHelper.beginDay(new Date()),
            oldEndTime:  commonVueHelper.beginDay(new Date()),
            siteId: this.$route.params.siteId,
            reportingTemplates: [],
            isCorrectEndDateTime: true,
            isTwoDateSelected: true,
            isCorrectPeriodDateTime: true,
            isValidationWithoutDate: true,
            schedulerList: [],
            weekdayList: [],
            vTime: "07:00",
            hour: "07",
            oldHour: "07",
            minute: "0",
            oldMinute: "0",
            weekDays: [],
            oldWeekDays: [],
            monthDays: [],
            oldMonthDays: [],
            isValidate: false,
            isFormDataChanged: false,
        };
    },
    created: function() {
        console.log("Component(editReport)::created() - called");
        this.reportingTemplates = this.getListTemplateReportName();
        this.schedulerList = this.getListScheduleReport();
        this.weekdayList = this.getListWeekday();
        this.getReportById({reportId: this.$route.params.reportId, siteId: this.$route.params.siteId});
    },
    mounted: function() {
        console.log("Component(editReport)::mounted() - Init metronic layout", this.$i18n.locale);
        KTLayout.init();

        this.initStartDateTimePicker();
        this.initEndDateTimePicker();
        // this.initTimePicker();

        this.initWeekDaysSelect2();
        this.initMonthDaysSelect2();
    },
    destroyed: function() {
        console.log("Component(editReport)::destroyed() - called");
        // Destroy Date Picker
        this.destroyDatePicker();

        //Remove time picker
        this.removeTimePicker();

        this.resetReportState();
    },
    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(this, "EditReport", "update", this.isFormDataChanged, to, from, next);
    },
    validations() {
        // -- validations -- List of controls to apply to validate a form.
        // --
        if (this.isValidationWithoutDate) {
            return {
                vTemplate: {
                    required
                },
                vKeyword: {
                    email
                },
                vReportType: {
                    required
                },
                vName: {
                    required,
                    minLen: minLength(3)

                },
                emails: {
                    required,
                    minLength: minLength(1)
                },
                vFileType: {
                    required
                }
            }
        } else {
            return {
                vTemplate: {
                    required
                },
                vKeyword: {
                    email
                },
                vReportType: {
                    required
                },
                vName: {
                    required,
                    minLen: minLength(3)

                },
                emails: {
                    required,
                    minLength: minLength(1)
                },
                vFileType: {
                    required
                },
                vStartTime: {
                    required
                },
                vEndTime: {
                    required
                },
                isCorrectPeriodDateTime: {
                    required,
                    mustBeTrue
                }
            }
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --

        vTemplate: function(valueTemplate) {
            if (valueTemplate === 'INSTANT_INVENTORY') {
                this.isCorrectPeriodDateTime = true;
                this.isValidationWithoutDate = true;
                this.isTwoDateSelected = true;
            } else {
                this.isCorrectPeriodDateTime = this.isPeriodDateInferiorToOneYear();
                this.isValidationWithoutDate = false;
                this.isTwoDateSelected = (this.vStartTime && this.vEndTime) ? true : false;
            }

            if (this.nameAction === "newReport") {
                this.validateCreateReport();
            }
        },

        currentReport: function(report) {
            console.log("Component(editReport)::watch(currentReport) - called with : ", report);
            if (report) {
                this.vName = report.name;
                this.oldName = report.name;
                this.vTemplate = report.reportTemplate;
                this.oldTemplate = report.reportTemplate;

                this.emails = report.emails.map(email => {
                    return { text: email, tiClasses: [ "ti-valid" ] }
                });
                this.oldEmails = report.emails.map(email => {
                    return { text: email, tiClasses: [ "ti-valid" ] }
                });

                this.vFileType = report.fileType;
                this.oldFileType = report.fileType;
                this.vReportType = report.reportType;
                this.oldReportType = report.reportType;

                if (report.from && report.reportTemplate !== 'INSTANT_INVENTORY') {

                    this.vStartTime = commonVueHelper.beginDay(new Date(report.from.split('T')[0]));
                    this.oldStartTime = commonVueHelper.beginDay(new Date(report.from.split('T')[0]));

                    this.initStartDateTimePicker();
                }

                if (report.to && report.reportTemplate !== 'INSTANT_INVENTORY') {

                    this.vEndTime = commonVueHelper.beginDay(new Date(report.to.split('T')[0]));
                    this.oldEndTime = commonVueHelper.beginDay(new Date(report.to.split('T')[0]));

                    this.initEndDateTimePicker();
                }

                if (report.scheduler) {
                    this.vRepeat = report.scheduler.repeat;
                    this.oldRepeat = report.scheduler.repeat;
                    this.hour = report.scheduler.hour;
                    this.oldHour = report.scheduler.hour;
                    this.minute = report.scheduler.minute;
                    this.oldMinute = report.scheduler.minute;
                    this.weekDays = report.scheduler.weekDays || [];
                    this.oldWeekDays = report.scheduler.weekDays || [];
                    this.monthDays = report.scheduler.monthDays || [];
                    this.oldMonthDays = report.scheduler.monthDays || [];
                    this.vTime = commonVueHelper.formatTimePicker(report.scheduler.hour, report.scheduler.minute, null, this.$i18n.locale);
                }

                this.initTimePicker();
                this.initWeekDaysSelect2();
                this.initMonthDaysSelect2();
            }
        },

        user: function(user) {
            console.log("Component(editReport)::watch(user) called with : ", user);
            if(user) {
                this.initTimePicker();
                this.initWeekDaysSelect2();
                this.initMonthDaysSelect2();
            }
        },
        
        vReportType() {
            this.validateCreateReport();
            this.onChangeForm();
        },

        vRepeat(repeat) {
            if (repeat === "MONTHLY") {
                commonVueHelper.destroySelect2($("#newReportForm_weekDayInput"));
                this.initMonthDaysSelect2();
            } else if (repeat === "WEEKLY") {
                commonVueHelper.destroySelect2($("#newReportForm_monthDayInput"));
                this.initWeekDaysSelect2();
            }

            this.validateCreateReport();
            this.onChangeForm();
        },

        vName() {
            this.validateCreateReport();
        },

        emails() {
            this.validateCreateReport();
            this.onChangeForm();
        },
        vTemplate() {
            this.onChangeForm();
        },

        vFileType() {
            this.onChangeForm();
        }

    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["userRoleNameForSite", "user", "currentReport"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getReportById", "updateReport",  "resetReportState"]),

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(editReport)::onCancelButton() - called");
            this.$router.push({ name: "reports" });
        },

        onUpdateReportButton() {
            console.log("Component(editReport)::onUpdateReportButton() - called");
            const data = {
                siteId: this.siteId,
                reportId: this.$route.params.reportId,
                name: this.vName,
                reportTemplate: this.vTemplate,
                emails: _.map(this.emails, "text"),
                fileType: this.vFileType,
                reportType: this.vReportType,
                timeZone: moment.tz.guess(),
            };

            if (this.vReportType === "SCHEDULED") {
                data.scheduler = {
                    repeat: this.vRepeat,
                    weekDays: (this.weekDays && this.vRepeat === "WEEKLY") ? this.weekDays : null,
                    monthDays: (this.monthDays && this.vRepeat === "MONTHLY") ? this.monthDays: null,
                    hour: parseInt(this.hour),
                    minute: parseInt(this.minute),
                }
            } else {
                data.scheduler = null
            }

            // start date
            if (this.vStartTime && this.vTemplate !== 'INSTANT_INVENTORY') {
                data.from = commonVueHelper.getStartOfDayToISOString(this.vStartTime);
            } else {
                data.from = null;
            }

            //  end date
            if (this.vEndTime && this.vTemplate !== 'INSTANT_INVENTORY') {
                data.to = commonVueHelper.getEndOfDayToISOString(this.vEndTime);
            } else {
                data.to = null;
            }
            this.isFormDataChanged = false;
            this.updateReport(data);
        },

        validateCreateReport() {
            if(this.$v.$invalid) {
                return this.isValidate = false;
            }
            if (this.vReportType === "SCHEDULED") {
                if(this.vRepeat === "WEEKLY" && (!this.weekDays || this.weekDays.length === 0)) {
                    return this.isValidate = false;
                }
    
                if(this.vRepeat === "MONTHLY" && (!this.monthDays || this.monthDays.length === 0)) {
                    return this.isValidate = false;
                }
            }
            return this.isValidate = true;
        },

        initWeekDaysSelect2() {
            var self = this;
            const reportWeekDaySelector = $("#newReportForm_weekDayInput")
            commonVueHelper.destroySelect2(reportWeekDaySelector);
            reportWeekDaySelector
                .select2({
                    placeholder: i18n.t("reporting_generateReportEnterDayOfWeek"),
                    width: "100%",
                    data: this.getListWeekday(),
                    closeOnSelect: true
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function(event) {
                    if($(this).val() && $(this).val().length) {
                        $(".select2-search--inline").addClass("hidden-select-search");
                    } else {
                        $(".select2-search--inline").removeClass("hidden-select-search");
                    }
                    self.weekDays = $(this).val();
                    self.validateCreateReport();
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        self.onChangeForm();
                    }
                });
            
            // Set value selected
            if (this.weekDays && this.vRepeat === "WEEKLY") {
                reportWeekDaySelector.val(this.weekDays);
                reportWeekDaySelector.trigger({ type: "change", params: { initialInit: true } })
            }

        },

        initMonthDaysSelect2() {
            var self = this;
            const data = listReportMonthDays.list.map(day => {
                return {id: day.key, text: day.value}
            });
            const reportMonthDaysSelector = $("#newReportForm_monthDayInput");
            commonVueHelper.destroySelect2(reportMonthDaysSelector);
            reportMonthDaysSelector
                .select2({
                    placeholder: i18n.t("reporting_generateReportEnterMonthDays"),
                    width: "100%",
                    data: data,
                    closeOnSelect: true
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function(event) {
                    if($(this).val() && $(this).val().length) {
                        $(".select2-search--inline").addClass("hidden-select-search");
                    } else {
                        $(".select2-search--inline").removeClass("hidden-select-search");
                    }
                    self.monthDays = $(this).val();
                    self.validateCreateReport();
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        self.onChangeForm();
                    }
                });
            // Set value selected
            if (this.monthDays && this.vRepeat === "MONTHLY") {
                reportMonthDaysSelector.val(this.monthDays);
                reportMonthDaysSelector.trigger({ type: "change", params: { initialInit: true } })
            }
        },

        // init start date picker
        initStartDateTimePicker() {
            let selector = $("#reporting_generateReportPeriodStartTimeDatePicker");
            commonVueHelper.initDatePickerWithoutClear(
                selector,
                this.vStartTime,
                new Date()
            );
            let self = this;
            selector.on("changeDate", event => {
                if (event.date) {
                    self.vStartTime = new Date(event.date.valueOf());
                    if (self.vEndTime && self.vStartTime && (!moment(self.vEndTime).diff(moment(self.vStartTime)) || self.vStartTime > self.vEndTime)) {
                        self.isCorrectEndDateTime = false;
                    } else {
                        self.isCorrectEndDateTime = true;
                    }
                } else {
                    self.vStartTime = null;
                    self.isCorrectEndDateTime = true;
                }
                self.isCorrectPeriodDateTime = (self.vTemplate !== 'INSTANT_INVENTORY') ? self.isPeriodDateInferiorToOneYear() : true;
                self.isTwoDateSelected = ((self.vStartTime && self.vEndTime) || (self.vTemplate === 'INSTANT_INVENTORY')) ? true : false;
                self.onChangeForm();
            });
            selector.on("focusout", event => {
                if (!selector.val()) {
                    self.vStartTime = null;
                    self.isCorrectEndDateTime = true;
                    self.isCorrectPeriodDateTime = (self.vTemplate !== 'INSTANT_INVENTORY') ? self.isPeriodDateInferiorToOneYear() : true;
                }
                self.isTwoDateSelected = ((self.vStartTime && self.vEndTime) || (self.vTemplate === 'INSTANT_INVENTORY')) ? true : false;
            });
        },

        // init end date picker
        initEndDateTimePicker() {
            let selector = $("#reporting_generateReportPeriodEndTimeDatePicker");
            commonVueHelper.initDatePickerWithoutClear(
                selector,
                this.vEndTime,
                new Date()
            );
            let self = this;
            selector.on("changeDate", event => {
                if (event.date) {
                    self.vEndTime = new Date(event.date.valueOf());
                    if (self.vStartTime && self.vEndTime && (!moment(self.vEndTime).diff(moment(self.vStartTime)) || self.vEndTime < self.vStartTime)) {
                        self.isCorrectEndDateTime = false;
                    } else {
                        self.isCorrectEndDateTime = true;
                    }
                } else {
                    self.vEndTime = null;
                    self.isCorrectEndDateTime = true;
                }

                self.isCorrectPeriodDateTime = (self.vTemplate !== 'INSTANT_INVENTORY') ? self.isPeriodDateInferiorToOneYear() : true;
                self.isTwoDateSelected = ((self.vStartTime && self.vEndTime) || (self.vTemplate === 'INSTANT_INVENTORY')) ? true : false;
                self.onChangeForm();
            });
            selector.on("focusout", event => {
                if (!selector.val()) {
                    self.vEndTime = null;
                    self.isCorrectEndDateTime = true;
                    self.isCorrectPeriodDateTime = (self.vTemplate !== 'INSTANT_INVENTORY') ? self.isPeriodDateInferiorToOneYear() : true;
                }
                self.isTwoDateSelected = ((self.vStartTime && self.vEndTime) || (self.vTemplate === 'INSTANT_INVENTORY')) ? true : false;
            });
        },

        initTimePicker() {
            var self = this;
            let isMeridian = this.$i18n.locale === "fr" ? false : true;

            this.removeTimePicker();
    
            const option = {
                defaultTime: this.vTime,
                minuteStep: true,
                showMeridian: isMeridian,
                snapToStep: false,
                modalBackdrop: false,
            }

            $('#reporting_timepicker').timepicker(option);
            $(".separator").hide();

            $('#reporting_timepicker').on("change", () => {
                let selectedTime = $('#reporting_timepicker').val();
                self.vTime = selectedTime;

                const _hour = selectedTime.split(":")[0];
                self.minute = selectedTime.split(":")[1].split(" ")[0];
                const meridian = selectedTime.split(":")[1].split(" ")[1];

                if (meridian && meridian === 'PM') {

                    self.hour = parseInt(_hour) !== 12 ? parseInt(_hour) + 12 : parseInt(_hour);
                }
                else if (!meridian || meridian === 'AM') {
                    self.hour = _hour;
                }
                self.isFormDataChanged = true;
            });
        },

        //remove the timepicker

        removeTimePicker() {
            const timepicker = $('#reporting_timepicker').data('timepicker');
            if (timepicker) {
                timepicker.remove();
            }
        },

        // destroy the two datepicker (to) and (from)
        destroyDatePicker() {
            $("#reporting_generateReportPeriodStartTimeDatePicker").datepicker('destroy');
            $("#reporting_generateReportPeriodEndTimeDatePicker").datepicker('destroy');
        },

        tagChanged(newKeywords) {
            // test added because johMun don't still added this option: Add options for emails #68 (https://github.com/JohMun/vue-tags-input/issues/68)

            for(let i = newKeywords.length - 1; i >= 0; i--) {
                if (!commonVueHelper.isValidEmail(newKeywords[i].text)) {
                    newKeywords.splice(i, 1);
                }
            }

            this.emails = newKeywords;
        },

        getListTemplateReportName() {
            let roleName = this.userRoleNameForSite;
            let listTemplateReportName = [];
            for (let idx = 0; idx < _TemplateReportName.list.length; idx++) {
                if ((roleName === "CAREGIVER") && (_TemplateReportName.list[idx] !== "INSTANT_INVENTORY") && (_TemplateReportName.list[idx] !== "GEONOTIFICATIONS_HISTORY")) {
                    continue;
                }
                listTemplateReportName.push(_TemplateReportName.list[idx]);
            }
            return listTemplateReportName;

        },

        getListScheduleReport() {
            const listSchedule = listReportSchedule.list;
            if(!this.vRepeat) {
                this.vRepeat = listSchedule[0]["key"];
                this.oldRepeat = listSchedule[0]["key"];
            }
            return listSchedule;
        },

        getListWeekday() {
            const weekdayList = listReportWeekdays.list;
            const weekdays = weekdayList.map((weekday) => {
                return {
                    id: weekday.index,
                    text: i18n.t("common_" + weekday.value.toLocaleLowerCase())
                };
            });
            return weekdays;
        },

        isPeriodDateInferiorToOneYear() {
            // verification if startDate and endDate inferior to one year (not necessary 365 days warning: Leap Year)
            if (this.vStartTime && this.vEndTime) {
                let yStartDate = this.vStartTime.getFullYear();
                let mStartDate = this.vStartTime.getMonth();
                let dStartDate = this.vStartTime.getDate();
                let yEndTime = this.vEndTime.getFullYear();
                let mEndTime = this.vEndTime.getMonth();
                let dEndTime = this.vEndTime.getDate();
                if (    (yEndTime === yStartDate)
                     || ((yEndTime - yStartDate === 1) && (mStartDate > mEndTime))
                     || ((yEndTime - yStartDate === 1) && (mStartDate === mEndTime) && (dStartDate > dEndTime))) {
                    return true;
                } else {
                    return false
                }
            } else {
                return true;
            }
        },
        onChangeForm(event) {
            if (
                this.vName !== this.oldName ||
                !_.isEqual(this.emails, this.oldEmails) ||
                this.vTemplate !== this.oldTemplate ||
                this.vFileType !== this.oldFileType ||
                this.vReportType !== this.oldReportType ||
                this.vRepeat !== this.oldRepeat ||
                parseInt(this.hour) !== parseInt(this.oldHour) ||
                parseInt(this.minute) !== parseInt(this.oldMinute) ||
                !_.isEqual(this.weekDays, this.oldWeekDays) ||
                !_.isEqual(this.monthDays, this.oldMonthDays)

            ) {
                return this.isFormDataChanged = true;
            }

            if (
                this.vStartTime && this.vTemplate!== 'INSTANT_INVENTORY' && !_.isEqual(this.vStartTime, this.oldStartTime) ||
                this.vEndTime && this.vTemplate!== 'INSTANT_INVENTORY' && !_.isEqual(this.vEndTime, this.oldEndTime)
            ) {
                return this.isFormDataChanged = true;
            }

            return this.isFormDataChanged = false;
        },
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle,
        "vue-tags-input": VueTagsInput
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.emptyColor {
    color: #b2b5ca;
}
#newReportForm_tagsInput {
    width: 100%;
    max-width: 100%;
}
.marginT0 {
    margin-top: 0px !important;
}
.schedule {
    display: flex;
    align-items: center;
}
</style>
